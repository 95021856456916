import { useEffect, useState } from 'react';
import axiosInstance, { getAuthorizationHeader } from '../../../myAxios';
import Girl, { GirlInterface } from '../components/UserInformationTable/Girl';

const ReviewGirls = () => {
  const [girls, setGirls] = useState<GirlInterface[]>([]);
  useEffect(() => {
    const getGirls = async () => {
      try {
        const res = await axiosInstance.get(`admins/girls/batch/v1`, {
          headers: {
            Authorization: await getAuthorizationHeader(),
            'Content-Type': 'application/json',
          },
        });
        console.log(res.data.data);
        setGirls(res.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    getGirls();
  }, []);
  //todo update girl
  return (
    <>
      {girls.map((girl, index) => (
        <>
          <Girl key={girl.id} girl={girl} />
          <button></button>
        </>
      ))}
    </>
  );
};
export default ReviewGirls;
