export const data = [
  {
    to: '/links',
    text: 'روابط التحميل',
    id: 'links',
  },
  {
    to: '/',
    text: 'الصفحة الرئيسية',
    id: 'home',
  },
];
