import styled from 'styled-components';
import Text from '../../../../../../components/Text';

export const Container = styled.section`
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${props => props.theme.colors.cards};
`;

export const FirstSentance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media screen and (max-width: 768px) {
    align-items: center;
    row-gap: 1.5rem;
  }
`;

export const TextWrap = styled.div`
  text-align: right;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  @media screen and (max-width: 768px) {
    align-items: center;
    row-gap: 1.5rem;
  }
`;
export const TextAndForm = styled.div`
  display: flex;
  text-align: right;
  margin-right: auto;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  row-gap: 9rem;
  @media screen and (max-width: 768px) {
    row-gap: 7rem;
  }
`;

export const SubscribeForm = styled.div`
  text-align: right;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  row-gap: 10px;
`;

export const FormInput = styled.input`
  padding-right: 10px;
  padding-left: 10px;
  outline: none;
  border-radius: 20px;
  height: 60px;
  width: 80%;
  border: none;
  font-size: 1rem;
  text-align: right;
  &:focus {
    text-align: left;
  }
  @media screen and (max-width: 768px) {
    height: 40px;
    font-size: clamp(0.8rem, 2.2vw, 1.1rem);
  }
`;

export const FormButton = styled.button`
  border-radius: 18px;
  margin-top: 1.5rem;
  white-space: nowrap;
  color: ${props => props.theme.colors.background};
  background-color: ${props => props.theme.colors.secondary};
  outline: none;
  width: 30%;
  height: 60px;
  font-size: 1.4rem;
  padding: 5px 15px;
  border: 2px solid black;
  cursor: pointer;
  overflow: hidden;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
  border: none;

  &:hover {
    color: white;
    transition: background-color 0.2s ease-in;
    background-color: ${props => props.theme.colors.accent};
  }
  @media screen and (max-width: 768px) {
    height: 40px;
    font-size: 1.1rem;
  }
`;
export const InputAndButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const BoldSpan = styled.span`
  font-weight: bold;
`;
export const DownloadButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 80%;
  @media screen and (max-width: 768px) {
    overflow: hidden;
  }
`;

export const DownlaodButtonImage = styled.img`
  display: flex;
  width: 90%;
  object-fit: cover;
`;
export const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  @media screen and (max-width: 768px) {
    width: 110%; //todo the image should be changed and this should be bigger
    overflow: hidden;
  }
`;
export const Img = styled.img`
  display: flex;
  max-width: 90%;
  object-fit: cover;
`;
export const TopCard = styled.div`
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 1250px) {
    padding-top: 3rem;
  }
`;

export const BlackHeroText = styled(Text)`
  font-size: clamp(2rem, 2.2vw, 3.5rem);
  text-align: center;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    max-width: none;
  }
`;

export const GreenHeroText = styled(Text).attrs({ color: 'accent' })`
  font-size: clamp(2rem, 2.2vw, 3.5rem);
  text-align: right;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    max-width: none;
    text-align: center;
  }
`;
export const LastHeroText = styled(Text)`
  font-size: clamp(1.2rem, 1vw, 1.4rem);
  text-align: right;
  @media screen and (max-width: 768px) {
    max-width: none;
    text-align: center;
  }
`;
