import { useState } from 'react';
import { Button, Input, LoginBox, LoginWrapper, Title } from './styles';
import axiosInstance from '../../../myAxios';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: any) => {
    e.preventDefault(); // Prevent the default form submission

    try {
      console.log('iam here');
      const res = await axiosInstance.post(`admins/login/v1`, {
        email: email,
        password: password,
      });

      localStorage.setItem('token', res.data.data.token);
      localStorage.setItem('userRole', res.data.data.role);

      navigate('/admin/home');
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <LoginWrapper>
      <LoginBox>
        <Title>Login</Title>
        <form onSubmit={handleLogin}>
          <Input
            value={email}
            type="text"
            placeholder="email"
            onChange={e => setEmail(e.target.value)}
          />
          <Input
            onChange={e => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
          />
          <Button type="submit">Login</Button>
        </form>
      </LoginBox>
    </LoginWrapper>
  );
};

export default Login;
