import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const DataSection = styled.div`
  flex: 2;
  padding: 20px;
  background-color: #f4f4f4;
  overflow-y: auto;
`;

export const ButtonSection = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  margin-bottom: 20px;
`;

export const DataTitle = styled.h2`
  margin-bottom: 10px;
`;

export const DataItem = styled.div`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
`;

export const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;
