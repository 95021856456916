import { Label } from './styles';
import Select from 'react-dropdown-select';
export interface BasicUserInformationIterface {
  publicId: string;
  socialLoginId: string;
  loginType: (typeof LoginType)[keyof typeof LoginType];
  verificationStatus: (typeof VerificationStatus)[keyof typeof VerificationStatus];
  appBootStage: (typeof AppBootStage)[keyof typeof AppBootStage];
  role: string;
}

interface EditorBasicUser
  extends UserEditorInput,
    BasicUserInformationIterface {}

export const AppBootStage = {
  ADD_MAN_GIRL: 'ADD_MAN_GIRL',
  VERIFICATION: 'VERIFICATION',
  VERIFY_AGAIN: 'VERIFY_AGAIN',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
  HOME: 'HOME',
};

export const LoginType = {
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
  APPLE: 'APPLE',
};

export const VerificationStatus = {
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
  VERIFY_AGAIN: 'VERIFY_AGAIN',
  PENDING_VERIFICATION: 'PENDING_VERIFICATION',
};

const dropdownOptions = {
  verificationStatus: [
    { value: VerificationStatus.VERIFIED, label: VerificationStatus.VERIFIED },
    {
      value: VerificationStatus.UNVERIFIED,
      label: VerificationStatus.UNVERIFIED,
    },
    {
      value: VerificationStatus.VERIFY_AGAIN,
      label: VerificationStatus.VERIFY_AGAIN,
    },
    {
      value: VerificationStatus.PENDING_VERIFICATION,
      label: VerificationStatus.PENDING_VERIFICATION,
    },
  ],
  appBootStage: [
    { value: 'ADD_MAN_GIRL', label: 'Add Man/Girl' },
    { value: 'VERIFICATION', label: 'Verification' },
    { value: 'VERIFY_AGAIN', label: 'Verify Again' },
    {
      value: 'PENDING_VERIFICATION',
      label: 'Pending Verification',
    },
    { value: 'HOME', label: 'Home' },
  ],
};

const BaiscUserInformation = (props: EditorBasicUser) => {
  return (
    <>
      <h2>User Information</h2> <p>Public ID = {props.publicId}</p>
      <p>Social Login ID = {props.socialLoginId}</p>
      <p>Login Type : {props.loginType}</p>
      <p>
        <Label>Verification Status</Label>
        <Select
          options={dropdownOptions.verificationStatus}
          onChange={() => null}
          values={[
            dropdownOptions.verificationStatus.find(
              option => option.value === props.verificationStatus,
            ) || [],
          ]}
        />
      </p>
      <p>
        <Label>App Boot Stage</Label>
        <Select
          options={dropdownOptions.appBootStage}
          onChange={() => null}
          values={[
            dropdownOptions.appBootStage.find(
              option => option.value === props.appBootStage,
            ) || [],
          ]}
        />
      </p>
      <p>Role = {props.role}</p>
    </>
  );
};
export default BaiscUserInformation;
export interface UserEditorInput {
  editMode: boolean;
}
