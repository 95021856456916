import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

// Define the type for the props
interface ProtectedRouteProps {
  children: ReactNode;
  allowedRoles: string[];
}

const ProtectedRoute = ({
  children,
  allowedRoles,
}: ProtectedRouteProps): JSX.Element => {
  const token = localStorage.getItem('token'); // Check if the user is authenticated
  const userRole = localStorage.getItem('userRole'); // Get the user role from local storage

  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/" />;
  }

  if (!allowedRoles.includes(userRole || '')) {
    // If the user's role is not allowed, redirect to a "not authorized" page or home page
    return <Navigate to="/not-authorized" />;
  }

  return <>{children}</>; // Return the children wrapped in a fragment
};

export default ProtectedRoute;
