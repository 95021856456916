import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.button<{ color: string; hoverColor: string }>`
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${props => props.color || '#007BFF'};
  color: white;
  &:hover {
    background-color: ${props => props.hoverColor || '#0056b3'};
  }
`;
