import Features from '../components/Features';
import Hero from '../components/Hero';
const Home = () => {
  return (
    <>
      <Hero />
      <Features />
    </>
  );
};
export default Home;
