import Text from '../../../../../../components/Text';
import {
  Container,
  BoldSpan,
  TextWrap,
  FirstSentance,
  ImgWrapper,
  Img,
  TopCard,
  SubscribeForm,
  TextAndForm,
  FormButton,
  FormInput,
  BlackHeroText,
  GreenHeroText,
  LastHeroText,
  InputAndButton,
  DownlaodButtonImage,
  DownloadButtonsContainer,
} from './styles';

const TopHero = () => {
  return (
    <TopCard>
      <ImgWrapper>
        <Img
          src={require('../../../../../../assets/images/landing-page-hero.png')}
          alt={'alt'}
        />
      </ImgWrapper>
      <TextAndForm>
        <TextWrap>
          <FirstSentance>
            <BlackHeroText>مستقبل الزواج بالمجتمع المحافظ</BlackHeroText>
            <GreenHeroText>زي زمان بس أسهل وأسرع</GreenHeroText>
          </FirstSentance>
          {/* <LastHeroText>
            مِنصة تحتوي على الأشخاص الحاصلين على{' '}
            <BoldSpan>ألقاب جامعية فقط</BoldSpan>
          </LastHeroText> */}
        </TextWrap>
        <SubscribeForm>
          {/* <Text>يمكنك التسجيل المُسبق للمنصة, والإطلاع على كل جديد</Text>
          <InputAndButton>
            <FormButton>تسجيل</FormButton>
            <FormInput placeholder="البريد الإلكتروني" />
          </InputAndButton> */}
          {/* download button  */}
          <DownloadButtonsContainer>
            <a href="https://apps.apple.com/il/app/id6504511766">
              <DownlaodButtonImage
                src={require('../../../../../../assets/images/apple.png')}
                alt={'alt'}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.zwjni.smartKhataba">
              <DownlaodButtonImage
                src={require('../../../../../../assets/images/google-play.png')}
                alt={'alt'}
              />
            </a>
          </DownloadButtonsContainer>
        </SubscribeForm>
      </TextAndForm>
    </TopCard>
  );
};
export default TopHero;
