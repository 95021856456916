import React, { useState } from 'react';
import axiosInstance, { getAuthorizationHeader } from '../../../../myAxios';

interface SocialMediaCodeVerificationInput {
  verify: any;
}
const SocialMediaCodeVerification = (
  props: SocialMediaCodeVerificationInput,
) => {
  const [inputValue, setInputValue] = useState('');
  const [showError, setShowError] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const VerifyUserPublicId = () => {
    // Define your condition here. For example, if inputValue is "hello", show the text
    if (inputValue.toLowerCase() === 'hello') {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  const searchUserPublicId = async (userPublicId: string) => {
    console.log('searching user public ID:', userPublicId);
    try {
      var res = await axiosInstance.get(
        `admins/users/checkUserPublicId/v1/${userPublicId}`,
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
            'Content-Type': 'application/json',
          },
        },
      );

      setIsUser(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };
  const verifyUser = (userPublicId: string) => {
    props.verify(userPublicId);
    setInputValue('');
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="user public ID"
      />
      {isUser && <p>User found!</p>}
      <button onClick={() => searchUserPublicId(inputValue)}>search</button>
      <button onClick={() => verifyUser(inputValue)}>verify</button>

      {/* Conditional text */}
      {showError && <p>You typed "hello"!</p>}
    </div>
  );
};

export default SocialMediaCodeVerification;
