import axios from 'axios';

export const getAuthorizationHeader = async () => {
  const token = await localStorage.getItem('token');
  return `Bearer ${token}`;
};
const axiosInstance = axios.create({
  baseURL: 'https://zwjni.com/api/',
  // baseURL: 'http://localhost:8080/api/',
});

export default axiosInstance;
