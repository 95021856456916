import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance, { getAuthorizationHeader } from '../../../myAxios';
import {
  Button,
  ButtonSection,
  DataItem,
  DataSection,
  PageWrapper,
  Title,
} from './styles';

import {
  ExternalData,
  setCities,
  setEthnicities,
} from '../../../store/externalDataSlice/externalDataSlice';
import { useAppDispatch } from '../../../store/hooks';
import UserInformationTable from '../components/UserInformationTable';

const AdminHome = () => {
  interface AdminSummary {
    countUsers: number;
    countVerifications: number;
    countMen: number;
    countGirls: number;
    countLeftSwipes: number;
    countRightSwipes: number;
    countAppointments: number;
  }
  const [data, setData] = useState<AdminSummary>();
  const [user, setUser] = useState();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  interface CrossPlatformDataFiltered {
    arabicWord: string;
    englishWord: string;
  }
  interface CrossPlatformData extends CrossPlatformDataFiltered {
    crossPlatformDataType: 'CITY' | 'ETHNICITY';
  }
  useEffect(() => {
    // Fetch data from the backend
    const getExternalData = async () => {
      try {
        const response = await axiosInstance.get('data/v1', {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const cities: ExternalData[] = response.data.data
          .filter((d: CrossPlatformData) => d.crossPlatformDataType === 'CITY') // Filter for CITY type
          .map(
            ({ arabicWord: label, englishWord: value }: CrossPlatformData) => ({
              label,
              value,
            }),
          );

        dispatch(setCities(cities));
        const ethnicities = response.data.data
          .filter(
            (d: CrossPlatformData) => d.crossPlatformDataType === 'ETHNICITY',
          )
          .map(
            ({ arabicWord: label, englishWord: value }: CrossPlatformData) => ({
              label,
              value,
            }),
          );
        dispatch(setEthnicities(ethnicities));
      } catch (error) {
        //todo return
        console.error('Error fetching external data:', error);
      }
    };
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('admins/summary/v1', {
          headers: {
            Authorization: await getAuthorizationHeader(),
            'Content-Type': 'application/json',
          },
        });
        setData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getExternalData();
    fetchData();
  }, []);

  return (
    <PageWrapper>
      <DataSection>
        <Title>Dashboard Data</Title>

        <p>count user = {data?.countUsers}</p>
        <p>count verification = {data?.countVerifications}</p>
        <p>count appointments ={data?.countAppointments}</p>
        <p>count men = {data?.countMen}</p>
        <p>count girls = {data?.countGirls}</p>
        <p>count right swipes = {data?.countRightSwipes}</p>
        <p>count left swipes = {data?.countLeftSwipes}</p>

        <UserInformationTable />
      </DataSection>
      <ButtonSection>
        <Title>Admin Controls</Title>
        <Button onClick={() => navigate('/admin/reviewGirls')}>
          review girls
        </Button>
        <Button onClick={() => navigate('/admin/reviewMen')}>review men</Button>

        <Button onClick={() => navigate('/admin/verification')}>
          Manage Verifications
        </Button>
        {/* Add more buttons as needed */}
      </ButtonSection>
    </PageWrapper>
  );
};
export default AdminHome;
