import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Nav = styled.nav`
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  background-color: ${props => props.theme.colors.cards};
  top: 0;
  width: 100%;
  transition: background-color 0.3s ease-in;
`;

export const NavbarContainer = styled.div`
  width: 100%;
  max-width: 100%; /* Ensure max-width does not exceed screen size */
  height: 80px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2rem;
  padding: 0 20px; /* Adjust padding to prevent overflow */
  display: flex;
  justify-content: start;
  align-items: center;
  box-sizing: border-box; /* Ensure padding is included in width calculation */

  @media screen and (max-width: 960px) {
    padding: 0 15px; /* Reduce padding for smaller screens */
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const NavLogo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavIcon = styled.img`
  width: 18rem;
  @media screen and (max-width: 568px) {
    width: 12rem;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 960px) {
    display: flex;
    z-index: 1;
    align-items: center;
    font-size: 1.8rem;
  }
`;

interface NavMenuInput {
  show: boolean;
}
export const NavMenu = styled.ul<NavMenuInput>`
  display: flex;
  /* justify-content: center; //Center items horizontally */

  list-style: none;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: fixed;
    padding-top: 30%;
    text-align: center;
    top: 0;
    left: 0;
    opacity: ${({ show }) => (show ? 1 : 0)};
    visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
    transform: translateY(${({ show }) => (show ? '0' : '-10px')});
    transition: opacity 0.5s ease;
    background-color: ${props => props.theme.colors.background};
  }

  > li:first-child {
    margin-left: auto;
  }
`;

export const NavItem = styled.li`
  height: 80px;
  cursor: pointer;
  text-align: center;
  @media screen and (max-width: 960px) {
    width: 100%;
    &:hover {
      border: none;
    }
  }
`;

export const NavLinks = styled.span`
  color: ${props => props.theme.colors.accent};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;

  &:hover {
    color: ${props => props.theme.colors.background};
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: #4b59f7;
      transition: all 0.3s ease;
    }
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
`;
//
