import { ThemeProvider } from 'styled-components';

import './App.css';

import { LightTheme } from './themes';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import Verification from './pages/AdminUser/Verification';

import Home from './pages/RegularUser/LandingPage/Home';
import Login from './pages/AdminUser/Login';
import AdminHome from './pages/AdminUser/AdminHome';

import NotAuthorized from './pages/components/NotAuthorized';
import ProtectedRoute from './ProtectedRoute';
import { Provider } from 'react-redux';
import { store } from './store';

import ReviewGirls from './pages/AdminUser/ReviewGirls';
import ReviewMen from './pages/AdminUser/ReviewMen';
import Navbar from './pages/RegularUser/LandingPage/components/Navbar';
import RedirectingLinks from './pages/RegularUser/LandingPage/RedirectingLinks';

const checkAdmin = () => {
  const token = localStorage.getItem('token');
  // Replace this with actual logic to check if the user is an admin
  return token && (token != null || token !== ''); // This is just an example, replace with your own logic
};

const UserRoutes = () => {
  return <Routes></Routes>;
};

const SupplierRoutes = () => {
  return <Routes></Routes>;
};

const AdminRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<AdminHome />} /> */}
      <Route path="/" element={<Login />} />
      <Route path="home" element={<AdminHome />} />
      <Route path="verification" element={<Verification />} />
      <Route path="reviewGirls" element={<ReviewGirls />} />
      <Route path="reviewMen" element={<ReviewMen />} />
    </Routes>
  );
};

function App() {
  // index.js or App.js
  if (window.location.hash && window.location.hash.startsWith('#!/')) {
    // Remove the #!/ part from the URL
    const cleanUrl = window.location.hash.replace('#!/', '/');
    window.history.replaceState(null, '', cleanUrl);
  }
  return (
    <>
      <ThemeProvider theme={LightTheme}>
        <Provider store={store}>
          <Router>
            <Navbar />
            <Routes>
              {/* Common Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/links" element={<RedirectingLinks />} />

              {/* Grouped Routes */}
              <Route
                path="/user/*"
                element={
                  <ProtectedRoute allowedRoles={['REGULAR_USER']}>
                    <UserRoutes />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/*"
                element={
                  <ProtectedRoute allowedRoles={['ADMIN']}>
                    <AdminRoutes />
                  </ProtectedRoute>
                }
              />
              <Route path="/supplier/*" element={<SupplierRoutes />} />
              <Route path="/not-authorized" element={<NotAuthorized />} />
              {/* Redirect all other routes to home or a default page */}
              <Route path="*" element={<Navigate to="/" />} />
              {/* Common Routes */}
              {/* <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} /> */}

              {/* Grouped Routes */}
              {/* <Route
                path="/user/*"
                element={
                  <ProtectedRoute allowedRoles={['REGULAR_USER']}>
                    <UserRoutes />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/*"
                element={
                  <ProtectedRoute allowedRoles={['ADMIN']}>
                    <AdminRoutes />
                  </ProtectedRoute>
                }
              />
              <Route path="/supplier/*" element={<SupplierRoutes />} />
              <Route path="/not-authorized" element={<NotAuthorized />} />
              {/* Redirect all other routes to home or a default page 
              <Route path="*" element={<Navigate to="/" />} /> */}
            </Routes>
          </Router>
        </Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
