import React from 'react';
import {
  FeatureText,
  FeatureTitle,
  FeatureWrapper,
  FeatureColumn,
  FeatureImageWrapper,
  FeatureName,
  FeatureTextWrapper,
  Container,
  InsideContainer,
  NameAndText,
} from './styles';
import { featuresData } from './FeaturesData';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const Features = () => {
  const initial = {
    y: 40,
    opacity: 0,
  };
  const animate = {
    y: 0,
    opacity: 1,
  };
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <InsideContainer>
        <FeatureTextWrapper>
          <FeatureTitle>مميزات الخطابة الذكية</FeatureTitle>
        </FeatureTextWrapper>
        <FeatureWrapper>
          {featuresData.map((el, index) => (
            <FeatureColumn
              initial={initial}
              animate={animate}
              transition={{ duration: 0.5 + index * 0.1 }}
              key={index}>
              <div
                style={{
                  color: themeContext.colors.accent,
                }}>
                {el.icon}
              </div>

              <NameAndText>
                <FeatureName>{el.name}</FeatureName>
                <FeatureText>{el.description}</FeatureText>
              </NameAndText>
            </FeatureColumn>
          ))}
        </FeatureWrapper>
      </InsideContainer>
    </Container>
  );
};

export default Features;
