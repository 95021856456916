import styled from "styled-components";

export const UserInfo = styled.div`
  text-align: left;
  margin-bottom: 10px;
`;

export const UserImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
`;
