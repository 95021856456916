import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { CgMenuRight } from 'react-icons/cg';
import { IconContext } from 'react-icons';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  MobileIcon,
  NavMenu,
  NavLinks,
  NavItem,
} from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { data } from './NavbarData';

const Navbar = () => {
  const [show, setShow] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  const handleClick = () => {
    setShow(!show);
  };

  const scrollTo = id => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const closeMobileMenu = (to, id) => {
    if (id && location.pathname === '/') {
      scrollTo(id);
    }

    navigate(to);
    setShow(false);
  };

  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to="/">
          <NavIcon
            src={require('../../../../../assets/images/logo.png')}
            alt="logo"
          />
        </NavLogo>
        <MobileIcon onClick={handleClick}>
          {show ? <FaTimes /> : <CgMenuRight />}
        </MobileIcon>
        <NavMenu show={show}>
          {data.map((el, index) => (
            <NavItem key={index}>
              <NavLinks onClick={() => closeMobileMenu(el.to, el.id)}>
                {el.text}
              </NavLinks>
            </NavItem>
          ))}
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
