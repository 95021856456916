import styled from 'styled-components';
import Text from '../../../../../components/Text';

export const Container = styled.section`
  padding-bottom: 10rem;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.theme.colors.background};
`;
export const InsideContainer = styled.section`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  width: 80%;
`;

export const FeatureTitle = styled(Text)`
  font-size: clamp(1rem, 10vw, 3.1rem);
  font-weight: ${props => props.theme.typography.fontFamily.bold};
`;

export const FeatureTextWrapper = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const FeatureWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 4rem;
  grid-gap: 2rem;

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 3rem;
  }

  @media screen and (max-width: 568px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const FeatureColumn = styled.div`
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  padding-top: 20px;
  border-radius: 20px;
  background-color: ${props => props.theme.colors.cards};
`;

export const FeatureImageWrapper = styled.div`
  margin-bottom: 1rem;
  border-radius: 50%;
  border: 2px solid #000;
  padding: 30px;
`;

export const NameAndText = styled.h3`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const FeatureName = styled(Text)`
  font-weight: ${props => props.theme.typography.fontFamily.medium};
  font-size: ${props => props.theme.typography.sizes.h1};
  font-size: clamp(1.1rem, 3vw, 1.5rem);

  @media screen and (max-width: 768px) {
    font-size: ${props => props.theme.typography.sizes.regular};
  }
`;

export const FeatureText = styled(Text)`
  font-size: ${props => props.theme.typography.sizes.small};

  font-weight: 400;
  margin: 1rem 0 auto;
  text-align: center;
  line-height: 1.73;
  @media screen and (max-width: 768px) {
    font-weight: 400;
    font-size: clamp(0.5rem, 3vw, 0.8rem);
  }
`;
