import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';

export interface ExternalData {
  value: string;
  label: string;
}

interface ExternalDataState {
  cities: ExternalData[];
  ethnicities: ExternalData[];
}

const initialState: ExternalDataState = {
  cities: [],
  ethnicities: [],
};

export const externalDataSlice = createSlice({
  name: 'externalData',
  initialState,
  reducers: {
    setCities: (state, action: PayloadAction<ExternalData[]>) => {
      state.cities = action.payload;
    },
    setEthnicities: (state, action: PayloadAction<ExternalData[]>) => {
      state.ethnicities = action.payload;
    },
    default: state => state,
  },
});

export const { setCities, setEthnicities } = externalDataSlice.actions;

export const selectCities = (state: RootState) => state.externalData.cities;
export const selectEthnicities = (state: RootState) =>
  state.externalData.ethnicities;

export default externalDataSlice.reducer;
