import React from 'react';
import styled from 'styled-components';

// Images for App Store and Play Store
import appStoreImage from '../../../../assets/images/apple-logo-white.png'; // Replace with your path
import playStoreImage from '../../../../assets/images/google-play-logo.png'; // Replace with your path
import tiktokImage from '../../../../assets/images/tiktok-logo.png'; // Replace with your path
import instagramImage from '../../../../assets/images/instagram-logo.png'; // Replace with your path
import facebookLogo from '../../../../assets/images/facebook-logo.png'; // Replace with your path
import Text from '../../../../components/Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${props => props.theme.colors.cards};
  min-height: 100vh;
`;
const ProfileImage = styled.img`
  width: 18rem;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const LinkList = styled.ul`
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 400px;
`;

const LinkItem = styled.li`
  margin-bottom: 15px;
  width: 100%;
`;

const LinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center; /* Center text */
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: ${props => props.theme.colors.accent};
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  border-radius: 8px;
  border: 2px solid ${props => props.theme.colors.accent};
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.colors.background};
    transform: scale(1.05);
  }

  img {
    margin-right: 10px; /* Add space between the image and the text */
    left: 30px; /* Position icon to the left */
    height: 40px;
    width: 40px;
    border-radius: 50%; /* Make the image circular */
    padding: 8px;
    background-color: ${props => props.theme.colors.cards};
  }

  @media (min-width: 768px) {
    padding: 12px;
    font-size: 16px;
  }
`;

const RedirectingLinks = () => {
  return (
    <Container>
      <ProfileImage
        src={require('../../../../assets/images/logo.png')}
        alt="Profile"
      />
      <LinkList>
        <LinkItem>
          <LinkButton
            href="https://apps.apple.com/il/app/id6504511766"
            target="_blank">
            <img src={appStoreImage} alt="App Store" />
            <Text fontSize="large" fontWeight="medium">
              حملي التطبيق من الآب ستور
            </Text>
          </LinkButton>
        </LinkItem>
        <LinkItem>
          <LinkButton
            href="https://play.google.com/store/apps/details?id=com.zwjni.smartKhataba"
            target="_blank">
            <img src={playStoreImage} alt="Google Play" />
            <Text fontSize="large" fontWeight="medium">
              حملي التطبيق من جوجل بلي
            </Text>
          </LinkButton>
        </LinkItem>
        <LinkItem>
          <LinkButton
            href="https://www.tiktok.com/@khataba_app"
            target="_blank">
            <img src={tiktokImage} alt="TikTok" />
            <Text fontSize="large" fontWeight="medium">
              صفحة التيك توك
            </Text>
          </LinkButton>
        </LinkItem>
        <LinkItem>
          <LinkButton
            href="https://www.instagram.com/khataba_app/"
            target="_blank">
            <img src={instagramImage} alt="Instagram" />
            <Text fontSize="large" fontWeight="medium">
              صفحة الانستجرام
            </Text>
          </LinkButton>
        </LinkItem>
        <LinkItem>
          <LinkButton
            href="https://www.facebook.com/profile.php?id=61561141025914"
            target="_blank">
            <img src={facebookLogo} alt="Facebook" />
            <Text fontSize="large" fontWeight="medium">
              صفحة الفيسبوك
            </Text>
          </LinkButton>
        </LinkItem>
      </LinkList>
    </Container>
  );
};

export default RedirectingLinks;
