import BottomHero from './Bottom';
import TopHero from './Top';
import { Container } from './styles';

const Hero = () => {
  return (
    <Container>
      <TopHero />
      {/* <BottomHero /> */}
    </Container>
  );
};
export default Hero;
