import { useEffect, useState } from 'react';
import { User } from '../../interfaces';
import BaiscUserInformation from './BaiscUserInformation/BaiscUserInformation';

import Feedback from './Feedback';
import Girl, { GirlInterface } from './Girl';
import Man, { ManInterface } from './Man';
import axiosInstance, { getAuthorizationHeader } from '../../../../myAxios';

const UserInformationTable = () => {
  useEffect(() => {
    const getUser = async () => {
      try {
        const userPublicId = 'WToROiQMQq2gCAEfL1C3uw';
        const response = await axiosInstance.get(
          `admins/users/userInformation/v1/${userPublicId}`,
          {
            headers: {
              Authorization: await getAuthorizationHeader(),
              'Content-Type': 'application/json',
            },
          },
        );
        console.log(response.data.data);
        setUser(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    getUser();
  }, []);
  const initialUserState: User = {
    publicId: '',
    socialLoginId: '',
    loginType: '',
    verificationStatus: '',
    appBootStage: '',
    role: '', // Replace USER_ROLE with an appropriate string value
    girls: [],
    men: [],
    feedbacks: [],
    roles: [],
  };
  const [user, setUser] = useState<User>(initialUserState);

  const updateManInUser = (updatedMan: ManInterface) => {
    const newMen = Array.from(
      user.men.map(man => (man.id === updatedMan.id ? updatedMan : man)),
    );

    setUser(prevUser => {
      return {
        ...prevUser,
        men: newMen,
      };
    });
    console.log('user', user);
  };

  const updateGirlInUser = (updatedgirl: GirlInterface) => {
    const newgirls = Array.from(
      user.girls.map(girl => (girl.id === updatedgirl.id ? updatedgirl : girl)),
    );

    setUser(prevUser => {
      return {
        ...prevUser,
        girls: newgirls,
      };
    });
    console.log('user', user);
  };

  const deleteFeedbackInUser = (feedbackId: number) => {
    const newFeedbacks = Array.from(
      user.feedbacks.filter(feedback => feedback.id !== feedbackId),
    );

    setUser(prevUser => {
      return {
        ...prevUser,
        feedbacks: newFeedbacks,
      };
    });
    console.log('user', user);
  };

  return (
    <>
      {user ? (
        <>
          <BaiscUserInformation
            editMode={false}
            publicId={'123'}
            socialLoginId={user.socialLoginId}
            loginType={user.loginType}
            verificationStatus={user.verificationStatus}
            appBootStage={user.appBootStage}
            role={user.role}
          />
          {user.girls.map((girl, index) => (
            <Girl key={girl.id} girl={girl} updateGirlInUser={updateGirlInUser} />
          ))}
          {user.men.map((man, index) => (
            <Man key={man.id} man={man} updateManInUser={updateManInUser} />
          ))}
          {user.feedbacks.map((feedback, index) => (
            <Feedback
              key={feedback.id}
              feedback={{
                id: feedback.id,
                createdAt: feedback.createdAt,
                message: feedback.message,
              }}
              deleteFeedback={deleteFeedbackInUser}
            />
          ))}
        </>
      ) : null}
    </>
  );
};
export default UserInformationTable;
