import { AiFillAlipayCircle } from 'react-icons/ai';
import { GrSecure } from 'react-icons/gr';
import { BsIncognito } from 'react-icons/bs';
import { FaMosque } from 'react-icons/fa6';
import { FaHeart } from 'react-icons/fa';
import { IoTimer } from 'react-icons/io5';
import { MdVerifiedUser } from 'react-icons/md';

export const featuresData = [
  {
    name: 'تتطبيق فقط للأخوات',
    description: '',
    icon: <MdVerifiedUser size={90} />,
    imgClass: 'one',
  },
  {
    name: 'السِّرِّية',
    description: '',
    icon: <BsIncognito size={90} />,
    imgClass: 'two',
  },
  {
    name: 'الخصوصية',
    description: '',
    icon: <GrSecure size={90} />,
    imgClass: 'three',
  },
  {
    name: 'تقديم اقتراحات مُلائمة',
    description: '',
    icon: <FaHeart size={90} />,
    imgClass: 'four',
  },
  {
    name: 'السُرعة والراحة',
    description: '',
    icon: <IoTimer size={90} />,
    imgClass: 'five',
  },
  {
    name: 'ضمن الأعراف والدين',
    description: '',
    icon: <FaMosque size={90} />,
    imgClass: 'six',
  },
];
