import styled from 'styled-components';

export const Container = styled.section`
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${props => props.theme.colors.cards};
`;
