import React from 'react';

const NotAuthorized = () => (
  <div>
    <h1>Not Authorized</h1>
    <p>You do not have permission to access this page.</p>
  </div>
);

export default NotAuthorized;
