import { DefaultTheme as DefaultThemeProps } from 'styled-components';

export enum Font {
  NotoSansArabicLight = 'tintaarabic-light',
  NotoSansArabicMedium = 'tintaarabic-medium',
  NotoSansArabicBold = 'tintaarabic-bold',
  NotoSansArabicSemiBold = 'tintaarabic-semibold',
  NotoSansArabicExtraBold = 'tintaarabic-extrabold',
  NotoSansArabicRegular = 'tintaarabic-regular',
}

export const DefaultConfigs = {
  typography: {
    fontFamily: {
      light: Font.NotoSansArabicLight,
      medium: Font.NotoSansArabicMedium,
      regular: Font.NotoSansArabicRegular,
      semiBold: Font.NotoSansArabicSemiBold,
      bold: Font.NotoSansArabicBold,
      extraBold: Font.NotoSansArabicExtraBold,
    },
    sizes: {
      h1: {
        size: 38,
      },
      h2: {
        size: 32,
      },
      h3: {
        size: 24,
      },
      large: {
        size: 18,
      },
      regular: {
        size: 16,
      },
      small: {
        size: 14,
      },
      extraSmall: {
        size: 12,
      },
    },
  },
};

//TODO update the dark mode theme.
export const DarkTheme: DefaultThemeProps = {
  ...DefaultConfigs,
  dark: true,
  colors: {
    primary: '#D45B90',
    text: '#ffffff',
    error: '#cc0000',
    background: '#07112D',
    secondary: '#111D40',
    border: '#111D40',
    accent: '#6BC9AA', // change to dark mode
    cards: '#9FD9C54D', //todo check if it works with 30% opacity change to dark mode
  },
};

export const LightTheme: DefaultThemeProps = {
  ...DefaultConfigs,
  dark: false,
  colors: {
    primary: '#56B696',
    text: '#070F26', //0C1915
    error: '#cc0000',
    background: '#F2F9F7',
    secondary: '#9FD9C5',
    border: '#f2f2f2',
    accent: '#6BC9AA',
    cards: '#9FD9C54D', //todo check if it works with 30% opacity
  },
};

export const DefaultTheme = LightTheme;
