import axiosInstance, { getAuthorizationHeader } from '../../../../../myAxios';

export interface FeedbackInterface {
  id: number;
  createdAt: Date;
  message: string;
}
interface FeedbackComponentInput {
  deleteFeedback: any;
  feedback: FeedbackInterface;
}
const Feedback = (props: FeedbackComponentInput) => {
  // todo delete feedback
  const deleteFeedback = async () => {
    try {
      const response = await axiosInstance.delete('feedbacks/v1', {
        headers: {
          Authorization: await getAuthorizationHeader(),
          'Content-Type': 'application/json',
        },
      });
      props.deleteFeedback(props.feedback.id);
      //tood i think you should update some state, is it the user state?
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <p>created at = {props.feedback.createdAt.getDate()}</p>
      <p>message = {props.feedback.message}</p>
      <button onClick={deleteFeedback}>delete</button>
    </>
  );
};
export default Feedback;
