import { DefaultTheme as DefaultThemeProps } from 'styled-components';
import styled, { css } from 'styled-components';
import '../themes';

export interface TextProps {
  fontSize?: keyof DefaultThemeProps['typography']['sizes'];
  color?: keyof DefaultThemeProps['colors'];
  fontWeight?: keyof DefaultThemeProps['typography']['fontFamily'];
}

export default styled.p<TextProps>`
  color: ${props => {
    const { colors } = props.theme;
    return colors?.[props.color ?? 'text'];
  }};
  margin-bottom: auto;
  margin-top: auto;
  font-family: ${props => {
    const { fontFamily } = props.theme.typography;
    return fontFamily?.[props.fontWeight ?? 'regular'];
  }};
  ${props => {
    const { sizes } = props.theme.typography;

    const selectedSize = sizes?.[props.fontSize ?? 'regular'];

    return css`
      font-size: ${selectedSize.size}px;
    `;
  }}
`;
