import { useEffect, useState } from 'react';
import axiosInstance, { getAuthorizationHeader } from '../../../myAxios';
import Girl, { GirlInterface } from '../components/UserInformationTable/Girl';
import Man, { ManInterface } from '../components/UserInformationTable/Man';

const ReviewMen = () => {
  const [men, setMen] = useState<ManInterface[]>([]);
  useEffect(() => {
    const getGirls = async () => {
      try {
        const res = await axiosInstance.get(`admins/men/batch/v1`, {
          headers: {
            Authorization: await getAuthorizationHeader(),
            'Content-Type': 'application/json',
          },
        });
        console.log(res.data.data);
        setMen(res.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    getGirls();
  }, []);
  //todo update man

  return (
    <>
      {men.map((man, index) => (
        <Man key={man.id} man={man} />
      ))}
    </>
  );
};
export default ReviewMen;
