import { useState } from 'react';
import axiosInstance, { getAuthorizationHeader } from '../../../myAxios';
import {
  Button,
  ButtonContainer,
  UserContainer,
  UserImage,
  UserInfo,
  VerificationTypeButtonContainer,
} from './styles';
import { log } from 'console';
import VerificationButton from './components/VerificationButton';
import SelfieVerification from './SelfieVerification';
import PhoneNumberVerification from './PhoneNumberVerification';
import SocialMediaCodeVerification from './SocialMediaCodeVerification';

const Verification = () => {
  const [verificationType, setVerificationType] = useState('');
  const [usersInformation, setUsersInformation] = useState([]);

  enum VerificationType {
    SELFIE = 'SELFIE',
    PHONE_CALL = 'PHONE_CALL',
    SOCIAL_MEDIA_CODE = 'SOCIAL_MEDIA_CODE',
    OTHER = 'OTHER',
  }

  interface UserVerificationProps {
    socialLoginId: string;
    asset: string;
    userPublicId: string;
  }

  const verify = async (userPublicId: string) => {
    try {
      await axiosInstance.post(
        `admins/verify/v1`,
        {},
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
            'Content-Type': 'application/json',
          },
          params: { userPublicId: userPublicId },
        },
      );

      setUsersInformation(prev =>
        prev.filter(
          (user: UserVerificationProps) => user.userPublicId !== userPublicId,
        ),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const askToVerifyAgain = async (userPublicId: string) => {
    try {
      const res = await axiosInstance.post(
        `admins/askToVerifyAgain/v1`,
        {},
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
            'Content-Type': 'application/json',
          },
          params: { userPublicId: userPublicId },
        },
      );
      setUsersInformation(prev =>
        prev.filter(
          (user: UserVerificationProps) => user.userPublicId !== userPublicId,
        ),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getUsersToVerify = async (verificationType: VerificationType) => {
    setVerificationType(verificationType);
    try {
      console.log(await getAuthorizationHeader());
      const res = await axiosInstance.get(`admins/getUsersToVerify/v1`, {
        headers: {
          Authorization: await getAuthorizationHeader(),
          'Content-Type': 'application/json',
        },
        params: {
          verificationType: verificationType,
        },
      });
      console.log(JSON.stringify(res.data.data));
      const users = res.data.data;
      setUsersInformation(users);
    } catch (error) {
      console.error(error);
    }
  };
  //todo add the user data also here, color the other things in red if it's not empty
  return (
    <>
      <VerificationTypeButtonContainer>
        <Button
          color="#007BFF"
          hoverColor="#0056b3"
          onClick={() => getUsersToVerify(VerificationType.SELFIE)}>
          SELFIE
        </Button>
        <Button
          color="#007BFF"
          hoverColor="#0056b3"
          onClick={() => getUsersToVerify(VerificationType.PHONE_CALL)}>
          PHONE_CALL
        </Button>
        <Button
          color="#007BFF"
          hoverColor="#0056b3"
          onClick={() =>
            setVerificationType(VerificationType.SOCIAL_MEDIA_CODE)
          }>
          SOCIAL_MEDIA_CODE
        </Button>
        <Button
          color="#007BFF"
          hoverColor="#0056b3"
          onClick={() => getUsersToVerify(VerificationType.OTHER)}>
          OTHER
        </Button>
      </VerificationTypeButtonContainer>
      {/* todo get all the data for the user */}
      {verificationType === VerificationType.SOCIAL_MEDIA_CODE ? (
        <SocialMediaCodeVerification verify={verify} />
      ) : (
        usersInformation.map((user: UserVerificationProps, index) => {
          return (
            <UserContainer key={index}>
              <UserInfo>
                {verificationType === VerificationType.SELFIE && (
                  <SelfieVerification imageUrl={user.asset} />
                )}
                {verificationType === VerificationType.PHONE_CALL && (
                  <PhoneNumberVerification phoneNumber={user.asset} />
                )}

                {verificationType === VerificationType.OTHER && (
                  <PhoneNumberVerification phoneNumber={user.asset} />
                )}
              </UserInfo>
              {/* <Man /> */}
              {/* <Girl /> */}
              <VerificationButton
                verify={() => verify(user.userPublicId)}
                askToVerifyAgain={() => askToVerifyAgain(user.userPublicId)}
              />
            </UserContainer>
          );
        })
      )}
    </>
  );
};
export default Verification;
