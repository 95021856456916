import Select from 'react-dropdown-select';
import styled from 'styled-components';
import { Nationality, Religion, SocialStatus } from '../../../interfaces';
import { useAppSelector } from '../../../../../store/hooks';
import axiosInstance, { getAuthorizationHeader } from '../../../../../myAxios';

export const ManDisableStatus = {
  NOT_DISABLED: 'NOT_DISABLED',
  ENUM_REVIEW: 'ENUM_REVIEW',
};

export interface ManInterface {
  id: number;
  age: number;
  profession: string;
  major: string;
  livingCity: string;
  otherLivingCity: string;
  village: string;
  ethnicity: string;
  otherEthnicity: string;
  nationality: (typeof Nationality)[keyof typeof Nationality];
  religion: (typeof Religion)[keyof typeof Religion];
  socialStatus: (typeof SocialStatus)[keyof typeof SocialStatus];
  partnerAgeFrom: number;
  partnerAgeTo: number;
  numOfRightSwipesThisWeek: number;
  numOfRightSwipesToday: number;
  suggestionUpdatedDate: string;
  disableStatus: (typeof ManDisableStatus)[keyof typeof ManDisableStatus];
  partnerLivingCities: string[];
  otherPartnerLivingCities: string;
  partnerEthnicities: string[];
  otherPartnerEthnicities: string;
}

interface ManComponentInput {
  updateManInUser?: any;
  man: ManInterface;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #fff;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
`;

const Label = styled.div`
  font-weight: bold;
  width: 150px;
`;

const Value = styled.div`
  flex: 1;
  min-width: 200px;
`;

const SelectWrapper = styled.div`
  flex: 1;
  min-width: 250px;
`;

const Man = (props: ManComponentInput) => {
  const dropdownOptions = {
    disableStatus: [
      {
        value: ManDisableStatus.NOT_DISABLED,
        label: ManDisableStatus.NOT_DISABLED,
      },
      {
        value: ManDisableStatus.ENUM_REVIEW,
        label: ManDisableStatus.ENUM_REVIEW,
      },
    ],
    cities: useAppSelector(state => state.externalData.cities),
    ethnicity: useAppSelector(state => state.externalData.ethnicities),
  };

  const handleChange = async (key: keyof ManInterface, value: any) => {
    console.log([key], value);
    try {
      const res = await axiosInstance.patch(
        `admins/men/v1/${props.man.id}`,
        { [key]: value },
        {
          headers: {
            Authorization: await getAuthorizationHeader(),
            'Content-Type': 'application/json',
          },
        },
      );
      console.log(res.data.data);
    } catch (error) {
      console.error(error);
    }

    const updatedMan = { ...props.man, [key]: value };
    if (props.updateManInUser !== undefined) {
      props.updateManInUser(updatedMan);
    }
  };

  return props.man ? (
    <Container>
      <Row>
        <Label>ID:</Label>
        <Value>{props.man.id}</Value>
      </Row>
      <Row>
        <Label>Age:</Label>
        <Value>{props.man.age}</Value>
      </Row>
      <Row>
        <Label>Profession:</Label>
        <Value>{props.man.profession}</Value>
      </Row>
      <Row>
        <Label>Major:</Label>
        <Value>{props.man.major}</Value>
      </Row>
      <Row>
        <Label>Living City:</Label>
        <SelectWrapper>
          <Select
            options={dropdownOptions.cities}
            onChange={values => handleChange('livingCity', values[0]?.value)}
            values={dropdownOptions.cities.filter(
              option => option.value === props.man.livingCity,
            )}
          />
        </SelectWrapper>
      </Row>
      <Row>
        <Label>Other Living City:</Label>
        <Value>{props.man.otherLivingCity}</Value>
      </Row>
      <Row>
        <Label>Village:</Label>
        <Value>{props.man.village}</Value>
      </Row>
      <Row>
        <Label>Ethnicity:</Label>
        <SelectWrapper>
          <Select
            options={dropdownOptions.ethnicity}
            onChange={values => handleChange('ethnicity', values[0]?.value)}
            values={dropdownOptions.ethnicity.filter(
              option => option.value === props.man.ethnicity,
            )}
          />
        </SelectWrapper>
      </Row>
      <Row>
        <Label>Other Ethnicity:</Label>
        <Value>{props.man.otherEthnicity}</Value>
      </Row>
      <Row>
        <Label>Nationality:</Label>
        <Value>{props.man.nationality}</Value>
      </Row>
      <Row>
        <Label>Religion:</Label>
        <Value>{props.man.religion}</Value>
      </Row>
      <Row>
        <Label>Social Status:</Label>
        <Value>{props.man.socialStatus}</Value>
      </Row>
      <Row>
        <Label>Partner Age From:</Label>
        <Value>{props.man.partnerAgeFrom}</Value>
      </Row>
      <Row>
        <Label>Partner Age To:</Label>
        <Value>{props.man.partnerAgeTo}</Value>
      </Row>
      <Row>
        <Label>Num Of Right Swipes This Week:</Label>
        <Value>{props.man.numOfRightSwipesThisWeek}</Value>
      </Row>
      <Row>
        <Label>Num Of Right Swipes Today:</Label>
        <Value>{props.man.numOfRightSwipesToday}</Value>
      </Row>
      <Row>
        <Label>Suggestion Updated Date:</Label>
        <Value>{props.man.suggestionUpdatedDate}</Value>
      </Row>
      <Row>
        <Label>Disable Status:</Label>
        <SelectWrapper>
          <Select
            options={dropdownOptions.disableStatus}
            onChange={values => handleChange('disableStatus', values[0]?.value)}
            values={dropdownOptions.disableStatus.filter(
              option => option.value === props.man.disableStatus,
            )}
          />
        </SelectWrapper>
      </Row>
      <Row>
        <Label>Partner Living Cities:</Label>
        <SelectWrapper>
          <Select
            options={dropdownOptions.cities}
            onChange={values =>
              handleChange(
                'partnerLivingCities',
                values.map(v => v.value),
              )
            }
            values={dropdownOptions.cities.filter(option =>
              props.man.partnerLivingCities.includes(option.value),
            )}
            multi
          />
        </SelectWrapper>
      </Row>
      <Row>
        <Label>Other Partner Living Cities:</Label>
        <Value>{props.man.otherPartnerLivingCities}</Value>
      </Row>
      <Row>
        <Label>Partner Ethnicities:</Label>
        <SelectWrapper>
          <Select
            options={dropdownOptions.ethnicity}
            onChange={values =>
              handleChange(
                'partnerEthnicities',
                values.map(v => v.value),
              )
            }
            values={dropdownOptions.ethnicity.filter(option =>
              props.man.partnerEthnicities.includes(option.value),
            )}
            multi
          />
        </SelectWrapper>
      </Row>
      <Row>
        <Label>Other Partner Ethnicities:</Label>
        <Value>{props.man.otherPartnerEthnicities}</Value>
      </Row>
    </Container>
  ) : null;
};

export default Man;
