import { Button, ButtonContainer } from './styles';
interface VerificationButtonInput {
  verify: any;
  askToVerifyAgain: any;
}
const VerificationButton = (props: VerificationButtonInput) => {
  return (
    <ButtonContainer>
      <Button color="#28a745" hoverColor="#218838" onClick={props.verify}>
        Verify
      </Button>
      <Button
        color="#ffc107"
        hoverColor="#e0a800"
        onClick={props.askToVerifyAgain}>
        Verify Again
      </Button>
    </ButtonContainer>
  );
};
export default VerificationButton;
